import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, mapTo, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { config } from './config';
import { QuestionnaireToken } from './questionnaire-token';

@Injectable({
  providedIn: 'root'
})
export class AuthQuestionnaireService {

  private JWT_QUEST = 'JWT_QUEST_TM_WEB';
  private JWT_CID = 'JWT_CID';
  private BRIDGE_URL = 'BRIDGE_URL';
  private GUESTNAME = 'GUESTNAME';
  private JWT_PRODUCT_NUMBER = 'JWT_PRODUCT_NUMBER';
  // private JWT_BOOKED = 'JWT_BOOKING_TOKEN';

  constructor(
    private http: HttpClient
  ) { }

  setToken(): Observable<boolean> {
    const formData: any = new FormData();
    // formData.append('grant_type', 'client_credentials');
    const params = {'grant_type': 'client_credentials'};
    const headers = { 'Authorization': 'Basic ' + btoa(config.clientId + ':' + config.clientSecret) };
    var data = 'grant_type=client_credentials';

    return this.http.post<any>(`${localStorage.getItem(this.BRIDGE_URL)}/oauth/token?${data}`, formData, { headers })
      .pipe(
        tap(token => {
          this.createQuestionnaireToken(token);
        }),
        mapTo(true),
        catchError(error => {
          console.log(error);
          return of(false);
        }));
  }

  refreshToken(): Observable<boolean> {
    const formData: any = new FormData();
    // formData.append('grant_type', 'client_credentials');
    const params = {'grant_type': 'client_credentials'};
    const headers = { 'Authorization': 'Basic ' + btoa(config.clientId + ':' + config.clientSecret) };
    var data = 'grant_type=client_credentials';

    return this.http.post<any>(`${localStorage.getItem(this.BRIDGE_URL)}/oauth/token?${data}`, formData, { headers })
      .pipe(
        tap(token => {
          this.createQuestionnaireToken(token);
        }),
        catchError(error => {
          // console.log(error);
          this.removeTokens();
          return of(false);
        }));
  }

  createQuestionnaireToken(token: QuestionnaireToken) {
    localStorage.setItem(this.JWT_QUEST, token.access_token);
  }

  private removeTokens() {
    localStorage.removeItem(this.JWT_QUEST);
    localStorage.removeItem(this.JWT_CID);
    localStorage.removeItem(this.BRIDGE_URL);
    localStorage.removeItem(this.GUESTNAME);
    localStorage.removeItem(this.JWT_PRODUCT_NUMBER);
  }

  getQuestToken() {
    return localStorage.getItem(this.JWT_QUEST);
  }

  logout() {
    this.removeTokens();
  }
}
