import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { config } from '../../utils/config';
import { AuthQuestionnaireService } from '../../utils/auth-questionnaire.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        public authService: AuthService, 
        public router: Router,
        public auth: AuthQuestionnaireService
        ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        let uri = localStorage.getItem('BRIDGE_URL');
        
        // if(request.url.includes(environment.apiBridge) && !request.url.includes(localStorage.getItem('JWT_CID'))){
        //     let [first, second]= request.url.split('null');
        //     uri = uri.replace('null', localStorage.getItem('JWT_CID'))
        //     request = request.clone({url: `${uri}${second}`});
        // }

        if(this.auth.getQuestToken()  && !request.url.startsWith(uri + '/oauth/token')){
            request = this.addToken(request, this.auth.getQuestToken());
        }

        return next.handle(request).pipe(
            catchError(error => {
                if (error instanceof HttpErrorResponse
                    && (error.status === 401)
                    && !request.url.startsWith(uri + '/oauth/token')) {
                        if(error.error.error === "invalid_token_not_found"){
                            this.auth.logout();
                            this.router.navigate(['/questionnaire/notfound']);
                            return of([]);
                        }else{
                            return this.handle401Error(request, next);
                        }
                } else {
                    return next.handle(request);
                }
            }),
        );
    }

    private addToken(request: HttpRequest<any>, token: string) {
        return request.clone({
            setHeaders: {
                'Authorization': `Bearer ${token}`,
            },
        });
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            return this.auth.refreshToken().pipe(
                switchMap((token: any) => {
                    this.isRefreshing = false;
                    this.refreshTokenSubject.next(token.access_token);
                    return next.handle(this.addToken(request, token.access_token));
                }),
                catchError(error => {
                    this.auth.logout();
                    this.router.navigate(['/questionnaire/notfound']);
                    return of([]);
                }),
                );

        } else {
            return this.refreshTokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(jwt => {
                    return next.handle(this.addToken(request, jwt));
                }));
        }
    }
}
