import { Component, OnInit } from '@angular/core';
import { ModelDataService, PageableModelData } from '../../../dtc/api/services/model-data.service';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column class="p-4">
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-footer [ngStyle]="statusShow()" class="foot-height p-0">
        <ngx-footer [title]='footerText' class="foots"></ngx-footer>
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent implements OnInit {

  footerText: string;

  constructor(private pageableModelDataService: ModelDataService<PageableModelData<any>>,){
  }

  ngOnInit(){
    this.pageableModelDataService.initialize('/api/systemSettings').subscribe(res => {
      if(res.getData()._embedded.systemSettings.length > 0){
        res.getData()._embedded.systemSettings.forEach(element => {
          if(element.name == 'footer_text'){
            this.footerText = element.value;
          }
        });
      }
    })
  }

  statusShow(){
    if(this.footerText == '' || this.footerText == null || this.footerText == undefined){
      return {visibility: 'hidden'};
    }
  }

}
