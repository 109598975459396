import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil, filter } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from './../../../dtc/auth/auth.service';
import { Subject } from 'rxjs';
import { ModelDataService, PageableModelData } from '../../../dtc/api/services/model-data.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  alertOpen: boolean = false;
  keyIcon: boolean = false;
  textWarning: string;
  now = new Date();
  user = {
    name: 'Budi Setyawan',
    picture: 'assets/images/boy.png'
  };
  lang: any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';
  toolTipProfile: string;
  userMenu = [{
      target: 'changePass', 
      title: 'Ganti Kata Sandi', 
      icon: 'lock-outline' 
    },{
      target: 'changeLang',
      title: 'Ganti Bahasa',
      icon: 'globe-outline'
    },{ 
      target: 'logout',
      title: 'Keluar', 
      icon: 'log-out-outline' 
    }
  ];

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private authService: AuthService,
    private router: Router,
    private service: ModelDataService<PageableModelData<any>>
    ) {
  }

  ngOnInit() {
    this.setLang();
    this.currentTheme = this.themeService.currentTheme;
    this.user.name = this.authService.getUser().name;
    this.user.picture = this.authService.getUser().webAvatar;

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => {
        this.currentTheme = themeName;
        if (themeName === 'dark') {
          this.themeService.appendLayoutClass('super-dark-theme');
        } else {
          this.themeService.removeLayoutClass('super-dark-theme');
        }
      });

    this.menuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'user-context-menu'),
        map(({ item: { target } }) => target),
      ).subscribe(target => {
        if (target === 'logout') {
          this.authService.logout();
          this.router.navigate(['/login']);
        }else if(target === 'changePass') {
          this.router.navigate(['/pages/changepass']);
        }else if(target === 'changeLang') {
          if(localStorage.getItem("defaultLanguage") === "id"){
            localStorage.setItem("defaultLanguage","en");
          } else {
            localStorage.setItem("defaultLanguage","id");
          }
          window.location.reload();
        }
      });
  }

  directMenu(menu: string){
    switch(menu){
      case 'logout':
      default :
        this.authService.logout();
        this.router.navigate(['/login']);
        break;
      
      case 'changePass':
        this.router.navigate(['/pages/changepass']);
        break;

      case 'changeLang':
        if(localStorage.getItem("defaultLanguage") === "id"){
          localStorage.setItem("defaultLanguage","en");
        } else {
          localStorage.setItem("defaultLanguage","id");
        }
        // window.location.reload();
        break;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  getExpired(){
    let message;
    this.service.initialize('/api/license').subscribe(res => {
      if(res.getData()['expiredDate'] != null){
        let expired = new Date(res.getData()['expiredDate']);
        const day = Math.floor((Date.UTC(expired.getFullYear(), expired.getMonth(), expired.getDate()) - Date.UTC(this.now.getFullYear(), this.now.getMonth(), this.now.getDate()) ) /(1000 * 60 * 60 * 24));
        // console.log(day);
        if(day > 0 && day <= 7){
          this.alertOpen = true;
          message = day + this.lang['home']['label']['day_left'];
        }else if(day == 0){
          this.alertOpen = true;
          message = this.lang['home']['label']['today'];
        }
        this.textWarning = message+this.lang['home']['label']['update_license'];
      }
    });
  }

  onClose(){
    this.alertOpen = false;
    this.keyIcon = true;
  }

  showAlert(){
    this.alertOpen = true;
    this.keyIcon = false;
  }

  setLang(){
    this.lang = this.authService.getLang().default;
    this.toolTipProfile = this.lang["general"]["button"]["tooltip_profile_setting"];
    this.userMenu = [
      {
        target: 'changePass', 
        title: this.lang["home"]["menu"]["change_password"],
        icon: 'lock-outline' 
      },{
        target: 'changeLang',
        title: this.lang["home"]["menu"]["change_lang"],
        icon: 'globe-outline'
      },{ 
        target: 'logout',
        title: this.lang["home"]["menu"]["logout"], 
        icon: 'log-out-outline' 
      }
    ];
  }
}
