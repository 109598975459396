import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { catchError, map, mapTo, tap } from 'rxjs/operators';
import { config } from './../config';
import { environment } from '../../../environments/environment';
import { Token } from './token';
import * as lang_id from "../../../assets/lang/lang_id.json";
import * as lang_en from "../../../assets/lang/lang_en.json";
import { NbComponentStatus, NbToastrService } from '@nebular/theme';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private JWT_TOKEN = 'JWT_TOKEN_TM_WEB';
  private REFRESH_TOKEN = 'REFRESH_TOKEN_TM_WEB';
  private defaultLanguage = 'defaultLanguage';
  private loggedUser: string;

  private currentVersion: string = '4.0.1';
  private versionApp: any = 40;

  constructor(
    private http: HttpClient, 
    private toastrService: NbToastrService, 
    public router: Router) {}
  login(user: { username: string, password: string }): Observable<boolean> {
    
    const formData: any = new FormData();
    formData.append('username', 'web-' + user.username);
    formData.append('password', user.password);
    formData.append('grant_type', 'password');
    const headers = { 'Authorization': 'Basic ' + btoa(config.clientId + ':' + config.cleintSecret) };
    var data = 'username='+'web-' + user.username+'&password='+user.password+'&grant_type=password';

    return this.http.post<any>(`${localStorage.getItem('BRIDGE_URL')}/oauth/token?${data}`, null, { headers })
      .pipe(
        tap(token => {
          this.doLoginUser(user.username, token);
        }),
        mapTo(true),
        catchError(error => {
          if(error.status === 404){
            this.showToast('warning','Terjadi kesalahan pada server.', 'Masuk Aplikasi: Informasi');
          }else if(error.status === 402 || (error.status === 400 && error.error.error_description == "Wrong username or password")){
            this.showToast('warning', 'Nama Pengguna atau Kata Sandi tidak terdaftar.', 'Masuk Aplikasi: Informasi');
          }else if(error.status === 400 && error.error.error_description === "User account has expired"){
            this.showToast('warning', 'Pengguna sudah tidak aktif.', 'Masuk Aplikasi: Informasi');
          }else{
            this.showToast('warning', 'Terjadi kesalahan saat menghubungkan ke server.', 'Masuk Aplikasi: Informasi');
          }
          return of(false);
        }));
  }

  logout() {
    this.doLogoutUser();
    return true;
    // return this.http.post<any>(`${config.apiUrl}/logout`, {
    //   'refreshToken': this.getRefreshToken(),
    // }).pipe(
    //   tap(() => this.doLogoutUser()),
    //   mapTo(true),
    //   catchError(error => {
    //     alert(error.error);
    //     return of(false);
    //   }));
  }

  isLoggedIn() {
    return !!this.getJwtToken();
  }

  refreshToken() {
    const formData: any = new FormData();
    formData.append('refresh_token', this.getRefreshToken());
    formData.append('grant_type', 'refresh_token');
    const headers = { 'Authorization': 'Basic ' + btoa(config.clientId + ':' + config.cleintSecret) };
    var data = 'refresh_token='+this.getRefreshToken()+'&grant_type=refresh_token';

    return this.http.post<any>(`${localStorage.getItem('BRIDGE_URL')}/oauth/token?${data}`, null, { headers })
    .pipe(
      tap((token: Token) => {
      // this.storeJwtToken(token.access_token);
      this.storeTokens(token);
      })
      ,catchError(error => {
        this.removeTokens();
        // alert(error.error);
        // this.showToast('danger', 'logoutku', 'refresh token');
        this.router.navigate(['login']);
        return of([]);
      })
    );
  }

  getLoginUser() {
    return this.loggedUser;
  }

  getJwtToken() {
    return localStorage.getItem(this.JWT_TOKEN);
  }

  private doLoginUser(username: string, token: Token) {
    this.loggedUser = username;
    this.storeTokens(token);
    // localStorage.setItem(this.defaultLanguage, 'id');
  }

  private doLogoutUser() {
    this.removeTokens();
    // this.showToast('success', 'Berhasil keluar!', 'Keluar');
    this.loggedUser = null;
  }

  private getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  // private storeJwtToken(jwt: string) {
  //   localStorage.setItem(this.JWT_TOKEN, jwt);
  // }

  private storeTokens(token: Token) {
    localStorage.setItem(this.JWT_TOKEN, token.access_token);
    localStorage.setItem(this.REFRESH_TOKEN, token.refresh_token);
    localStorage.setItem(this.defaultLanguage, (localStorage.getItem(this.defaultLanguage)? localStorage.getItem(this.defaultLanguage):'id'));
  }

  private removeTokens() {
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);    
    localStorage.removeItem(this.defaultLanguage);
    localStorage.removeItem("reloadMe");
  }

  showToast(status: NbComponentStatus, content: string, title: string) {
    this.toastrService.show(content, title,  {status, duration:5000} );
  }

  getAuthorities(){
    var rights = this.parseJwt(localStorage.getItem(this.JWT_TOKEN));
    return rights.authorities;
  }

  getUser(){
    var user = this.parseJwt(localStorage.getItem(this.JWT_TOKEN));
    return user;
  }

  checkRights(role: string){
    var rights = this.getAuthorities();
    if(rights.includes(role+"_READ_ONLY")){
      return true;
    }
    else{
      return false;
    }
  }

  getVersion(){
    let result = {
      'current':this.currentVersion,
      'version':this.versionApp
    };
    return result;
  }

  checkModule(role: string){
    var rights = this.getAuthorities();
    if(rights.includes(role+"_FULL_ACCESS") || 
    rights.includes(role+"_READ_ONLY")){
      return true;
    }
    else{
      return false;
    }
  }

  parseJwt (token) {
    if(token !== null){
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    }else{
      this.router.navigate(['login']);
      return of([]);
    }
  }

  getLang(){
    var lang: any;
    if (localStorage.getItem("defaultLanguage") == "id" || 
    localStorage.getItem("defaultLanguage") == undefined){
      lang = lang_id;
    } else {
      lang = lang_en;
    }
    return lang;
  }

  getDefaultLang() {
    var language = localStorage.getItem("defaultLanguage");
    return language;
  }

}
