import { Component, OnInit, Input } from '@angular/core';
import { ModelDataService, PageableModelData } from '../../../dtc/api/services/model-data.service';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
  <span class="created-by">{{title}}</span>
  `,
})
export class FooterComponent{

  @Input() title: string;
}
