import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { retry, catchError, map, timeout } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';


@Injectable({
  providedIn: 'root',
})
export class ModelDataService<T extends ModelData> {

  private data: T;
  private BRIDGE_URL = 'BRIDGE_URL';

  // headers = new HttpHeaders()
  // .set('Content-Type', 'application/json')
  // .set('Access-Control-Allow-Origin', '*')
  // .set('Access-Control-Allow-Credentials', 'true')
  // .set('Access-Control-Allow-Methods', 'GET')
  // .set('Access-Control-Allow-Headers', 'Authorization, Lang')

  constructor(
    private http: HttpClient,
    ) { }

  public initialize(path: string | T, params?: HttpParams | {
    [param: string]: string | string[];
  }): Observable<ModelDataService<T>> {
    if (typeof path === 'string') {
      // do something
      return this.http.get<T>(localStorage.getItem(this.BRIDGE_URL) + path, { params: params })
        .pipe(
          // retry(1),
          map(dt => {
            this.data = dt;
            return this;
          }),
          catchError(this.handleError),
        );
    } else {
      this.data = path;
      return of(this);
    }
  }

  public initializer(path: string | T, params?: HttpParams | {
    [param: string]: string | string[] | number | number[]| boolean | boolean[] | {};
  }): Observable<ModelDataService<T>> {
    if (typeof path === 'string') {
      // do something
      return this.http.post<T>(localStorage.getItem(this.BRIDGE_URL) + path, params)
        .pipe(
          retry(0),
          map(dt => {
            this.data = dt;
            return this;
          }),
          catchError(this.handleError),
        );
    } else {
      this.data = path;
      return of(this);
    }
  }

  public updates(path: string | T, params?: HttpParams | {
    [param: string]: string | string[] | number | number[]| boolean | boolean[] | {};
  }): Observable<ModelDataService<T>> {
    if (typeof path === 'string') {
      // do something
      return this.http.patch<T>(localStorage.getItem(this.BRIDGE_URL) + path, params)
        .pipe(
          retry(0),
          map(dt => {
            this.data = dt;
            return this;
          }),
          catchError(this.handleError),
        );
    } else {
      this.data = path;
      return of(this);
    }
  }

  public deletes(path: string){
    if (typeof path === 'string') {
      // do something
      return this.http.delete<T>(localStorage.getItem(this.BRIDGE_URL) + path)
        .pipe(
          retry(0),
          map(dt => {
            this.data = dt;
            return this;
          }),
          catchError(this.handleError),
        );
    } else {
      this.data = path;
      return of(this);
    }
  }

  public deleteChoice(path: string | T, params?: HttpParams | {
    [param: string]: string | string[];
  }): Observable<any>{
    if (typeof path === 'string') {
      // do something
      return this.http.post<T>(localStorage.getItem(this.BRIDGE_URL) + path, params)
        .pipe(
          retry(0),
          map(dt => {
            this.data = dt;
            return dt;
          }),
          catchError(this.handleError),
        );
    } else {
      this.data = path;
      return of(this);
    }
  }

  public deleteMulti(path: string | T, params): Observable<any>{
    if (typeof path === 'string') {
      // do something
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        body: params
      }
      return this.http.delete(localStorage.getItem(this.BRIDGE_URL) + path, options)
        .pipe(
          retry(0),
          map(dt => {
            return dt;
          }),
          catchError(this.handleError),
        );
    } else {
      this.data = path;
      return of(this);
    }
  }

  public deleteMultiParam(path: string | T, params?: HttpParams | {
    [param: string]: string | string[];
  }){
    if (typeof path === 'string') {
      // do something
      return this.http.delete<T>(localStorage.getItem(this.BRIDGE_URL) + path, {params: params})
        .pipe(
          retry(0),
          map(dt => {
            this.data = dt;
            return this;
          }),
          catchError(this.handleError),
        );
    } else {
      this.data = path;
      return of(this);
    }
  }

  public deleteCache(path: string): Observable<any>{
    if (typeof path === 'string') {
      // do something
      return this.http.delete<T>(`https://bridge2.telmessenger.com/_clients/clear-cache/` + path)
        .pipe(
          retry(0),
          map(dt => {
            this.data = dt;
            return this;
          }),
          catchError(this.handleError),
        );
    } else {
      this.data = path;
      return of(this);
    }
  }

  public downloadFile(path: string | T, params?: HttpParams | {
    [param: string]: string | string[];
  }): any{
    if (typeof path === 'string') {
      return this.http.get(localStorage.getItem(this.BRIDGE_URL) + path, { params: params, responseType: 'blob' }).pipe(
        retry(0),
        map(dt => {
          return dt;
          // var type = 'application/pdf';
          // if(formatType == 'HTML' || formatType == 'CSV'){
          //   type = 'text/'+formatType.toLowerCase();
          // }
          // else if(formatType == 'XLSX'){
          //   type = 'application/xlsx'
          // }

          // return new Blob([dt], { type: type });
        }),
        catchError(this.handleError),
      );
    } else {
      this.data = path;
      return of(this);
    }
  }

  public footer(path: string | T, params?: HttpParams | {
    [param: string]: string | string[];
  }): any{
    return this.http.get(''+path, {responseType: 'json'})
      .pipe(
        retry(0),
        map(dt => {
          return dt;
        }),
        catchError(this.handleError),
      );
  }

  public upload(path: string | T, formData: FormData): any {
    if (typeof path === 'string') {
      // do something
      return this.http.post(localStorage.getItem(this.BRIDGE_URL) + path, formData)
        .pipe(
          retry(0),
          map(dt => {
            // this.data = dt;
            return dt;
          }),
          catchError(this.handleError),
        );
    } else {
      // this.data = path;
      return path;
    }
  }

  public restoreGet(path: string | T, params?: HttpParams | {
    [param: string]: string | string[];
  }): any {
    if (typeof path === 'string') {
      // do something
      return this.http.get(localStorage.getItem(this.BRIDGE_URL) + path, { params: params })
        .pipe(
          map(dt => {
            // this.data = dt;
            return dt;
          }),
          catchError(this.handleError),
        );
    } else {
      this.data = path;
      return of(this);
    }
  }

  public get<X>(linkName: string, params?: HttpParams | {
    [param: string]: string | string[];
  }): Observable<X> {
    return this.http.get<X>(this.data._links[linkName].href, { params: params });
  }

  getData(): T { return this.data; }

  handleError(error: any) {
    return throwError(error);
  }
}


export class ModelData {
  _links: any[];
}


export class PageableModelData<T extends ModelData> extends ModelData {
  _embedded: { [k: string]: T };
  page: Page;
}

export class Page {
  size: number;
  totalElements: number;
  totalPages: number;
  number: number;
}
