import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthQuestionnaireService } from './auth-questionnaire.service';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
})
export class UtilsModule { }
